import React, { useEffect, useRef, useState } from "react";
import {
  DashbordHomeTitle,
  InputContainer,
  InputLabel,
  InputMain,
} from "../DashbordHome/DashbordHomeEelements";
import {
  CreateAccountContainer,
  DashboardAppointmentContainer,
  AddUserButton,
} from "./DashboardManageUsersElements";
import axios from "axios";
import { ItemLoading } from "../../../components/ItemLoading";
import Cookies from "universal-cookie";
import UserTr from "../../../components/UserTr";
import {
  AuthAlert,
  AuthSelect,
} from "../../../components/AuthForm/AuthFormElements";
import { Button } from "../../../components/Button";
import { LoadingIcon } from "../../Home/HomeElements";
import Swal from "sweetalert2";
import Modal from "../../../components/Modal";

export const cookies = new Cookies();

const DashboardManageUsers = () => {
  const [state, setState] = useState({
    data: [],
    requestNum: 0,
    loading: false,
    errorText: "",
  });
  const [province, setProvince] = useState([]);
  const [city, setCity] = useState([]);
  const [cityCurrent, setCityCurrent] = useState([]);
  const [loading, setLoading] = useState(true);
  const usernameInputSignUp = useRef(null);
  const phoneInputSignUp = useRef(null);
  const passwordInputSignUp = useRef(null);
  const passwordConfirmInputSignUp = useRef(null);
  const nationalCodeInputSignUp = useRef(null);
  const provinceInputSignUp = useRef(null);
  const cityInputSignUp = useRef(null);
  const rolInputSignUp = useRef(null);
  const [toggleModal, setToggleModal] = useState(false);

  useEffect(() => {
    if (loading === true) {
      handelAddOptions();
    }
  });
  const handelInfoModal = () => {
    setToggleModal(!toggleModal);
  };
  const handelChangeCity = (e) => {
    const current = city.filter(
      (el) => el.province_id === Number(e.target.value)
    );
    setCityCurrent([...current]);
  };
  const handelAddOptionsSingUp = () => {
    const optionsProvince = {
      method: "GET",
      url: `https://nobat-api.zagreus.company/api/data/provinces`,
      headers: {
        Accept: "application/json",
        "Content-Type":
          "multipart/form-data; boundary=---011000010111000001101001",
      },
    };
    axios
      .request(optionsProvince)
      .then((response) => {
        if (response.status === 200) {
          setProvince([...response.data]);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const optionsCity = {
      method: "GET",
      url: `https://nobat-api.zagreus.company/api/data/cities`,
      headers: {
        Accept: "application/json",
        "Content-Type":
          "multipart/form-data; boundary=---011000010111000001101001",
      },
    };
    axios
      .request(optionsCity)
      .then((response) => {
        if (response.status === 200) {
          setCity([...response.data]);
          const current = response.data.filter((e) => e.province_id === 1);
          setCityCurrent([...current]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handelReload = () => {
    handelAddOptions();
  };
  const handelSignUp = (e) => {
    e.preventDefault();
    setState({ ...state, loading: true });
    const provinceInputSignUpName = province.filter(
      (e) => e.id === Number(provinceInputSignUp.current.value)
    );
    console.log(passwordInputSignUp.current.value);
    const form = new FormData();
    form.append("full_name", usernameInputSignUp.current.value);
    form.append("number", phoneInputSignUp.current.value);
    form.append("password", passwordInputSignUp.current.value);
    form.append(
      "password_confirmation",
      passwordConfirmInputSignUp.current.value
    );
    form.append("national_code", nationalCodeInputSignUp.current.value);
    form.append("province", provinceInputSignUpName[0].name);
    form.append("city", cityInputSignUp.current.value);
    form.append("role_id", rolInputSignUp.current.value);

    const options = {
      method: "POST",
      url: "https://nobat-api.zagreus.company/api/admin/users/create",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${cookies.get("token")}`,
      },
      data: form,
    };

    axios
      .request(options)
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            text: "کاربر جدید با موفقیت ثبت شد",
            confirmButtonText: "بستن",
          });
          handelReload();
          handelInfoModal();
          setState({ ...state, loading: false });
          usernameInputSignUp.current.value = "";
          phoneInputSignUp.current.value = "";
          passwordInputSignUp.current.value = "";
          passwordConfirmInputSignUp.current.value = "";
          nationalCodeInputSignUp.current.value = "";
          provinceInputSignUpName[0].name = "";
          cityInputSignUp.current.value = "";
          rolInputSignUp.current.value = "";
        }
      })
      .catch((error) => {
        setState({ ...state, loading: false });
        console.log(error);
        if (error.response !== undefined) {
          setState({ ...state, errorText: error.response.data.message });
        }
      });
  };
  const handelAddOptions = () => {
    const options = {
      method: "GET",
      url: `https://nobat-api.zagreus.company/api/admin/users`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${cookies.get("token")}`,
      },
    };
    axios
      .request(options)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setState({ ...state, data: [...response.data.data] });
          handelAddOptionsSingUp();
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <DashboardAppointmentContainer>
      <DashbordHomeTitle>
        <h2>مدیریت کاربران</h2>
        <span>
          امروز،
          {new Date().toLocaleDateString("fa-IR", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </span>
      </DashbordHomeTitle>
      {toggleModal === true ? (
        <Modal handelModalToggle={handelInfoModal} title="">
          {state.errorText !== "" ? (
            <AuthAlert>{state.errorText}</AuthAlert>
          ) : (
            ""
          )}
          <CreateAccountContainer>
            <InputContainer style={{ marginTop: 0 }}>
              <InputLabel>نوع حساب</InputLabel>
              <AuthSelect ref={rolInputSignUp}>
                <option value="0" defaultValue>
                  کاربر
                </option>
                <option value="1">مدیرکل</option>
                <option value="2">دفتر</option>
              </AuthSelect>
            </InputContainer>
            <InputContainer style={{ marginTop: 0 }}>
              <InputLabel>نام و نام خانوادگی</InputLabel>
              <InputMain type="text" ref={usernameInputSignUp} />
            </InputContainer>
            <InputContainer style={{ marginTop: 0 }}>
              <InputLabel>شماره تماس</InputLabel>
              <InputMain type="text" ref={phoneInputSignUp} />
            </InputContainer>
            <InputContainer style={{ marginTop: 0 }}>
              <InputLabel>رمزعبور</InputLabel>
              <InputMain type="password" ref={passwordInputSignUp} />
            </InputContainer>
            <InputContainer style={{ marginTop: 0 }}>
              <InputLabel>تکرار رمز عبور</InputLabel>
              <InputMain type="password" ref={passwordConfirmInputSignUp} />
            </InputContainer>
            <InputContainer style={{ marginTop: 0 }}>
              <InputLabel>کد ملی</InputLabel>
              <InputMain type="text" ref={nationalCodeInputSignUp} />
            </InputContainer>
            <InputContainer style={{ marginTop: 0 }}>
              <InputLabel>استان</InputLabel>
              <AuthSelect
                ref={provinceInputSignUp}
                onChange={(e) => handelChangeCity(e)}
              >
                {province.map((e, i) => {
                  if (i === 0) {
                    return (
                      <option value={e.id} key={i} defaultValue>
                        {e.name}
                      </option>
                    );
                  } else {
                    return (
                      <option value={e.id} key={i}>
                        {e.name}
                      </option>
                    );
                  }
                })}
              </AuthSelect>
            </InputContainer>
            <InputContainer style={{ marginTop: 0 }}>
              <InputLabel>شهر</InputLabel>
              <AuthSelect ref={cityInputSignUp}>
                {cityCurrent.map((e, i) => {
                  if (i === 0) {
                    return (
                      <option value={e.name} key={i} defaultValue>
                        {e.name}
                      </option>
                    );
                  } else {
                    return (
                      <option value={e.name} key={i}>
                        {e.name}
                      </option>
                    );
                  }
                })}
              </AuthSelect>
            </InputContainer>
          </CreateAccountContainer>
            <Button type="submit" onClick={handelSignUp}>
              {state.loading ? <LoadingIcon /> : "ایجاد کاربر"}
            </Button>
        </Modal>
      ) : (
        ""
      )}
      <AddUserButton type="button" onClick={handelInfoModal}>
        افزودن کاربر
      </AddUserButton>
      {loading === true ? (
        <ItemLoading />
      ) : state.data.length === 0 ? (
        <p className="empty">درحال حاضر کاربری ثبت نشده است.</p>
      ) : (
        <>
          <div className="tabel-responsive">
            <table>
              <thead>
                <tr>
                  <th scope="col">نوع حساب</th>
                  <th scope="col">نام کاربر</th>
                  <th scope="col">شماره تماس </th>
                  <th scope="col">استان</th>
                  <th scope="col">شهر</th>
                  <th scope="col">کدملی</th>
                  <th scope="col">*</th>
                </tr>
              </thead>
              <tbody>
                {state.data.length !== 0
                  ? state.data.map((e, i) => (
                      <UserTr
                        key={i}
                        handelReload={handelReload}
                        userId={e.id}
                        roleId={e.role_id}
                        name={e.full_name}
                        phoneNumber={e.number}
                        province={e.province}
                        city={e.city}
                        nationalCode={e.national_code}
                      />
                    ))
                  : ""}
              </tbody>
            </table>
          </div>
        </>
      )}
    </DashboardAppointmentContainer>
  );
};

export default DashboardManageUsers;
