import styled from "styled-components";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

export const HomeContainer = styled.main`
  width: 1300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  max-width: 100%;
  padding: 0 24px;
  margin: 0 auto;
  height: auto;
  padding-top: 100px;
  padding-bottom: 20px;
`;
export const HomeMainContent = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 0;
`;
export const HomeDetails = styled.div`
  width: calc(100% / 2);
  h1 {
    color: #06283d;
    font-size: 2.4rem;
    margin-top: 150px;
    @media screen and (max-width: 992px) {
      margin-top: 0px;
    }
    @media screen and (max-width: 768px) {
      font-size: 2rem;
    }
  }
  p {
    color: #606060;
    margin-top: 20px;
    text-align: justify;
    padding: 0 0 0 34px;
    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }
  @media screen and (max-width: 992px) {
    width: 100%;
  }
`;
export const HomeAuth = styled.div`
  width: calc(100% / 2);
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-top: 40px;
  }
`;
export const LoadingIcon = styled(AiOutlineLoading3Quarters)`
  position: relative;
  top: -2px;
  width: 20px;
  height: auto;
  -webkit-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
`;
