import styled from "styled-components";

export const DahsboardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const DashboardMenu = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  @media screen and (max-width: 992px) {
    position: static;
    width: 100%;
    height: auto;
  }
`;
export const DashboardMainContent = styled.div`
  width: 75%;
  padding: 24px;
  margin-right: auto;
  @media screen and (max-width: 992px) {
    width: 100%;
  }
`;
export const HamburgerMenu = styled.div`
  display: none;
  padding: 12px 24px;
  @media screen and (max-width: 992px) {
    display: block;
    width: 100%;
    background: #256d85;
    svg {
      font-size: 2rem;
      cursor: pointer;
      color: #fff;
    }
  }
`;
export const HamburgerMenuOutSide = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
  background-color: #0000006c;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
`;
