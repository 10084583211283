import React, { useEffect, useState } from "react";
import { DashbordHomeTitle } from "../DashbordHome/DashbordHomeEelements";
import { DashboardAppointmentContainer } from "./DashboardAppointmentElements";
import Cookies from "universal-cookie";
import axios from "axios";
import { ItemLoading } from "../../../components/ItemLoading";

export const cookies = new Cookies();

const DashboardAllAppointment = () => {
  const [state, setState] = useState({
    data: [],
    requestNum: 0,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading === true) {
      handelAddOptions();
    }
  });

  const handelAddOptions = () => {
    console.log("first");
    const options = {
      method: "GET",
      url: `https://nobat-api.zagreus.company/api/admin/appointments`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${cookies.get("token")}`,
      },
    };
    axios
      .request(options)
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          setLoading(false);
          setState({ ...state, data: [...response.data.data] });
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  return (
    <DashboardAppointmentContainer>
      <DashbordHomeTitle>
        <h2> لیست تمام نوبت ها</h2>
        <span>
          امروز،
          {new Date().toLocaleDateString("fa-IR", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </span>
      </DashbordHomeTitle>
      {loading === true ? (
        <ItemLoading />
      ) : state.data.length === 0 ? (
        <p className="empty">درحال حاضر نوبتی ثبت نشده است.</p>
      ) : (
        <div className="tabel-responsive">
          <table>
            <thead>
              <tr>
                <th scope="col">شماره دادنامه</th>
                <th scope="col">شماره پرونده</th>
                <th scope="col">نام دفتر </th>
                <th scope="col">شماره تماس </th>
                <th scope="col">آدرس</th>
                <th scope="col">نام متقاضی</th>
                <th scope="col">شماره تماس متقاضی</th>
                <th scope="col">آدرس متقاضی</th>
                <th scope="col">تاریخ نوبت</th>
              </tr>
            </thead>
            <tbody>
              {state.data.length !== 0
                ? state.data.map((e, i) => (
                    <tr key={i}>
                      <td>{e.shomare_dadname}</td>
                      <td>{e.shomare_parvande}</td>
                      <td>{e.office.name}</td>
                      <td>{e.office.number}</td>
                      <td>{e.office.address}</td>
                      <td>{e.user.full_name}</td>
                      <td>{e.user.number}</td>
                      <td>
                        {e.user.province} ، {e.user.city}
                      </td>
                      <td>
                        {new Date(
                          e.appointment_time.slice(0, 4),
                          e.appointment_time.slice(5, 7),
                          e.appointment_time.slice(8, 10)
                        ).toLocaleDateString("fa-IR")}
                      </td>
                    </tr>
                  ))
                : ""}
            </tbody>
          </table>
        </div>
      )}
    </DashboardAppointmentContainer>
  );
};

export default DashboardAllAppointment;
