import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import {
  DashbordHomeContainer,
  DashbordHomeTitle,
  InputContainer,
  InputLabel,
  InputMain,
  FormContainer,
} from "./DashbordProfileEelements";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { ItemLoading } from "../../../components/ItemLoading";
import { Button } from "../../../components/Button";
import { LoadingIcon } from "../../Home/HomeElements";
import { AuthAlert } from "../../../components/AuthForm/AuthFormElements";

export const cookies = new Cookies();

const DashbordProfile = () => {
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (loading === true) {
      handelGetProfile();
    }
  });

  const nameInput = useRef(null);
  const addressInput = useRef(null);
  const numberInput = useRef(null);

  const handelGetProfile = () => {
    const options = {
      method: "GET",
      url: `https://nobat-api.zagreus.company/api/office`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${cookies.get("token")}`,
      },
    };
    axios
      .request(options)
      .then((response) => {
        if (response.status === 200) {
          setState({ data: { ...response.data } });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handelConfrim = (e) => {
    e.preventDefault();
    setLoadingButton(true);
    const form = new FormData();
    form.append("name", nameInput.current.value);
    form.append("address", addressInput.current.value);
    form.append("number", numberInput.current.value);

    const options = {
      method: "POST",
      url: "https://nobat-api.zagreus.company/api/office/update",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${cookies.get("token")}`,
      },
      data: form,
    };

    axios
      .request(options)
      .then((response) => {
        if (response.status === 200) {
          setLoadingButton(false);
          Swal.fire({
            icon: "success",
            text: "تغییرات با موفقیت ، انجام شد.",
            confirmButtonText: "بستن",
          });
          setError("");
        }
      })
      .catch((error) => {
        setLoadingButton(false);
        console.log(error);
        if (error.response !== undefined) {
          setError(error.response.data.message);
        }
      });
  };
  return (
    <DashbordHomeContainer>
      <DashbordHomeTitle>
        <h2>حساب کاربری</h2>
        <span>
          امروز،
          {new Date().toLocaleDateString("fa-IR", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </span>
      </DashbordHomeTitle>
      {loading === true ? (
        <ItemLoading />
      ) : (
        <>
          {error !== "" ? <AuthAlert>{error}</AuthAlert> : ""}
          <FormContainer>
            <InputContainer>
              <InputLabel>نام دفتر</InputLabel>
              <InputMain
                type="text"
                ref={nameInput}
                defaultValue={state.data.name}
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>شماره تماس</InputLabel>
              <InputMain
                type="text"
                ref={numberInput}
                defaultValue={state.data.number}
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>آدرس</InputLabel>
              <InputMain
                type="text"
                ref={addressInput}
                defaultValue={state.data.address}
              />
            </InputContainer>
            <Button
              type="button"
              onClick={(e) => {
                handelConfrim(e);
              }}
              disabled={!loadingButton}
            >
              {loadingButton ? <LoadingIcon /> : "تایید"}
            </Button>
          </FormContainer>
        </>
      )}
    </DashbordHomeContainer>
  );
};

export default DashbordProfile;
