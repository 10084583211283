import React from "react";
import { ApiErrorText, ApiErrorContainer } from "./ApiErrorElements";

const ApiErrorPage = ({
  errorText = "مشکلی پیش آمده است ، دقایقی دیگر مجددا تلاش کنید.",
}) => {
  return (
    <ApiErrorContainer>
      <ApiErrorText>{errorText}</ApiErrorText>
    </ApiErrorContainer>
  );
};

export default ApiErrorPage;
