import styled from "styled-components";

export const AuthFormContainer = styled.div`
  background-color: #fff;
  width: 500px;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 0 25px -13px #06283d63;
`;
export const AuthFormTabs = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
`;
export const AuthFormTab = styled.li`
  background-color: #e9e9e9;
  padding: 12px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  &.active {
    background-color: #256d85;
    color: #fff;
  }
`;
export const AuthLogin = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
`;
export const Form = styled.form`
  display: block;
`;
export const AuthSingUp = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
`;
export const InputContainer = styled.div`
  position: relative;
  display: block;
  margin: 35px 0;
`;
export const InputLabel = styled.label`
  display: block;
  position: absolute;
  right: 10px;
  top: -6px;
  background-color: #fff;
  padding: 0 12px;
  font-size: 14px;
  color: #256d85;
`;
export const InputMain = styled.input`
  display: block;
  width: 100%;
  padding: 14px 15px;
  border-radius: 9px;
  border: 1px solid #ebebeb;
`;
export const AuthAlert = styled.p`
  padding: 10px;
  margin: 15px 0 10px;
  font-size: 14px;
  border-right: 4px solid #eb2f2f;
  background-color: #ee707021;
  color: #eb2f2f;
`;
export const AuthSelect = styled.select`
  display: block;
  width: 100%;
  padding: 14px 15px;
  background-color: #fff;
  border-radius: 9px;
  border: 1px solid #ebebeb;
`;