import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import LoadingPage from "../../components/LoadingPage";
import ApiErrorPage from "../../components/ApiError";
import AuthForm from "../../components/AuthForm";
import Navbar from "../../components/Navbar";
import {
  HomeAuth,
  HomeContainer,
  HomeDetails,
  HomeMainContent,
} from "./HomeElements";

const Home = ({
  tokenValidation,
  loadingPage,
  apiError,
  handelTokenValidation,
  SetProfiletState,
}) => {
  useEffect(() => {
    document.title = "سامانه نوبت دهی | خانه";
  });
  if (loadingPage) {
    return <LoadingPage />;
  } else if (apiError) {
    return <ApiErrorPage />;
  } else {
    if (tokenValidation === true) {
      return <Navigate replace to="/dashboard" />;
    } else {
      return (
        <HomeContainer>
          <Navbar />
          <HomeMainContent>
            <HomeDetails>
              <h1>سامانه نوبت دهی آنلاین</h1>
              <p>
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله
                در ستون و سطرآنچنان که لازم است
              </p>
            </HomeDetails>
            <HomeAuth>
              <AuthForm
                handelTokenValidation={handelTokenValidation}
                SetProfiletState={SetProfiletState}
              />
            </HomeAuth>
          </HomeMainContent>
        </HomeContainer>
      );
    }
  }
};

export default Home;
