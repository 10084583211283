import React from "react";
import { LoadingIcon, LoadingPageContainer } from "./LoadingPageElements";

const LoadingPage = () => {
  return (
    <LoadingPageContainer>
      <LoadingIcon />
    </LoadingPageContainer>
  );
};

export default LoadingPage;
