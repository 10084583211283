import React, { useEffect, useState } from "react";
import { DashbordHomeTitle } from "../DashbordHome/DashbordHomeEelements";
import { DashboardAppointmentContainer } from "./DashboardAppointmentElements";
import Cookies from "universal-cookie";
import axios from "axios";
import { ItemLoading } from "../../../components/ItemLoading";

export const cookies = new Cookies();

const DashboardOfficeAppointment = () => {
  const [state, setState] = useState({
    data: [],
    taskText: "",
    requestNum: 0,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (state.data.length === 0) {
      handelAddOptions();
    }
  });

  const handelAddOptions = () => {
    const options = {
      method: "GET",
      url: `https://nobat-api.zagreus.company/api/office/list`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${cookies.get("token")}`,
      },
    };
    axios
      .request(options)
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          setLoading(false);
          setState({ ...state, data: [...response.data.data] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <DashboardAppointmentContainer>
      <DashbordHomeTitle>
        <h2>لیست دفاتر </h2>
        <span>
          امروز،
          {new Date().toLocaleDateString("fa-IR", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </span>
      </DashbordHomeTitle>
      {loading === true ? (
        <ItemLoading />
      ) : state.data.length === 0 ? (
        <p className="empty">درحال حاضر دفتری ثبت نشده است.</p>
      ) : (
        <div className="tabel-responsive">
          <table>
            <thead>
              <tr>
                <th scope="col">نام دفتر</th>
                <th scope="col">آدرس</th>
              </tr>
            </thead>
            <tbody>
              {state.data.length !== 0
                ? state.data.map((e, i) => (
                    <tr key={i}>
                      <td>{e.name}</td>
                      <td>{e.address}</td>
                    </tr>
                  ))
                : ""}
            </tbody>
          </table>
        </div>
      )}
    </DashboardAppointmentContainer>
  );
};

export default DashboardOfficeAppointment;
