import React from "react";
import {
  SidebarContainer,
  SideItem,
  SideNav,
  SideHeader,
  SideImg,
  SideName,
  SideLogout,
  SidePhoneNumber,
} from "./SidebarElements";
import { MdDashboard } from "react-icons/md";
import { BsCalendar2DateFill } from "react-icons/bs";
import { FaUserCircle, FaUsers } from "react-icons/fa";
import { HiDocumentText, HiOfficeBuilding, HiTable } from "react-icons/hi";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";

export const cookies = new Cookies();
const Sidebar = ({
  profileState,
  show,
  handelHideMenuMobile,
  handelLogOut,
}) => {
  const navigate = useNavigate();

  return (
    <SidebarContainer show={show}>
      <SideHeader>
        <SideImg>{profileState.full_name.slice(0, 1)}</SideImg>
        <SideName>{profileState.full_name}</SideName>
        <SidePhoneNumber>{profileState.number}</SidePhoneNumber>
      </SideHeader>
      <SideNav>
        {profileState.role_title === "client" ? (
          <SideItem to="" onClick={handelHideMenuMobile} end>
            <MdDashboard />
            داشبور
          </SideItem>
        ) : null}
        {profileState.role_title === "client" ? null : (
          <SideItem to="" onClick={handelHideMenuMobile} end>
            <HiTable />
            لیست نوبت ها
          </SideItem>
        )}
        {profileState.role_title === "client" ? (
          <SideItem to="appointment" onClick={handelHideMenuMobile}>
            <HiDocumentText />
            لیست نوبت ها
          </SideItem>
        ) : null}
        {profileState.role_title !== "sudo" ? (
          <SideItem to="office" onClick={handelHideMenuMobile}>
            <HiOfficeBuilding />
            لیست دفاتر
          </SideItem>
        ) : null}

        {profileState.role_title === "office" ? (
          <SideItem to="profile" onClick={handelHideMenuMobile}>
            <FaUserCircle />
            حساب کاربری
          </SideItem>
        ) : null}
        {profileState.role_title === "sudo" ? (
          <SideItem to="users" onClick={handelHideMenuMobile}>
            <FaUsers />
            مدیریت کاربران
          </SideItem>
        ) : null}
        {profileState.role_title === "sudo" ? (
          <SideItem to="officeadmin" onClick={handelHideMenuMobile}>
            <HiOfficeBuilding />
            مدیریت دفاتر
          </SideItem>
        ) : null}
            {profileState.role_title === "sudo" ? (
          <SideItem to="allappointment" onClick={handelHideMenuMobile}>
            <BsCalendar2DateFill />
            لیست تمام نوبت ها
          </SideItem>
        ) : null}
        <SideLogout
          onClick={() => {
            handelLogOut();
            navigate("/");
          }}
        >
          خروج از ناحیه کاربری
        </SideLogout>
      </SideNav>
    </SidebarContainer>
  );
};

export default Sidebar;
