import styled from "styled-components";

export const DashboardAppointmentContainer = styled.div`
  display: block;
  .empty {
    margin-top: 30px;
    text-align: center;
    font-family: "yekan-bold";
    color: #1a4a5a;
    font-size: 1.5rem;
  }
  table {
    width: 100%;
    margin-top: 30px;
    thead {
      th {
        color: #fff;
        background: #1a4a5a;
        font-family: "yekan-bold";
        font-weight: normal;
        &:last-child {
          border-radius: 15px 0 0 15px;
        }
        &:first-child {
          border-radius: 0 15px 15px 0;
        }
      }
    }
    th,
    td {
      padding: 0.6rem 0.8rem;
      text-align: right;
      line-height: 27px;
      &:last-child {
        width: 50%;
      }
    }
    tr {
      &:nth-child(even) {
        td {
          background-color: #f3f3f3;
          &:last-child {
            border-radius: 15px 0 0 15px;
          }
          &:first-child {
            border-radius: 0 15px 15px 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 880px) {
    .tabel-responsive {
      overflow: hidden;
      overflow-x: auto;
      table {
        width: 900px;
      }
    }
  }
`;
