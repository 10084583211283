import styled from "styled-components";

export const PrintPageContainer = styled.div`
  display: block;
  padding: 24px;
  .title {
    text-align: center;
  }
  .box {
    margin-top: 20px;
    border: 1px solid #626262;
    padding: 20px;
    border-radius: 8px;
  }
  span{
    display: inline-block;
  }
`;
