import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import {
  ModalBox,
  ModalClose,
  ModalContainer,
  ModalTitle,
} from "./ModalElements";

const Modal = ({ title, children, handelModalToggle }) => {
  return (
    <ModalContainer>
      <OutsideClickHandler
        onOutsideClick={() => {
          handelModalToggle();
        }}
      >
        <ModalBox>
          <ModalClose onClick={handelModalToggle} />
          <ModalTitle>{title}</ModalTitle>
          {children}
        </ModalBox>
      </OutsideClickHandler>
    </ModalContainer>
  );
};

export default Modal;
