import styled from "styled-components";

export const DashboardAppointmentContainer = styled.div`
  display: block;
  .empty {
    margin-top: 30px;
    text-align: center;
    font-family: "yekan-bold";
    color: #1a4a5a;
    font-size: 1.5rem;
  }
  table {
    width: 100%;
    margin-top: 30px;
    thead {
      th {
        color: #fff;
        background: #1a4a5a;
        font-family: "yekan-bold";
        font-weight: normal;
        &:last-child {
          border-radius: 15px 0 0 15px;
        }
        &:first-child {
          border-radius: 0 15px 15px 0;
        }
      }
    }
    th,
    td {
      padding: 0.6rem 0.5rem;
      text-align: center;
      line-height: 27px;
    }
    tr {
      &:nth-child(even) {
        td {
          background-color: #f3f3f3;
          &:last-child {
            border-radius: 15px 0 0 15px;
          }
          &:first-child {
            border-radius: 0 15px 15px 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 880px) {
    .tabel-responsive {
      overflow: hidden;
      overflow-x: auto;
      table {
        width: 900px;
      }
    }
  }
`;
export const CreateAccountContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
  margin-top: 15px;
  @media screen and (max-width: 992px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const AddUserButton = styled.button`
  position: absolute;
  left: 24px;
  top: 24px;
  z-index: 1;
  display: block;
  width: 120px;
  height: 46px;
  background-color: #256d85;
  color: #fff;
  padding: 15px;
  text-align: center;
  border-radius: 9px;
  cursor: pointer;
  &:hover {
    background-color: #1c5365;
    color: #fff;
  }
  @media screen and (max-width: 992px) {
    top: 93px;
  }
`;
