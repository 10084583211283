import React from "react";
import styled from "styled-components";
import ApiErrorPage from "../components/ApiError";
import LoadingPage from "../components/LoadingPage";

export const NotFoundContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  background-color: #256d85;
  h1 {
    color: #fff;
    font-size: 5.3rem;
  }
  p {
    color: #fff;
    margin-top: 10px;
    font-size: 1.2rem;
  }
`;

const NotFoundPage = ({ loadingPage, apiError }) => {
  if (loadingPage) {
    return <LoadingPage />;
  } else if (apiError) {
    return <ApiErrorPage />;
  } else {
    return (
      <NotFoundContainer>
        <h1>404</h1>
        <p>صفحه مورد نظر یافت نشد</p>
      </NotFoundContainer>
    );
  }
};

export default NotFoundPage;
