import styled from "styled-components";

export const DashbordHomeContainer = styled.div`
  display: block;
`;
export const DashbordHomeTitle = styled.div`
  h1 {
    color: #232323;
  }
  span {
    font-family: "yekan";
    display: block;
    color: #959595;
    margin-top: 13px;
  }
`;
export const DashbordHomeNear = styled.div`
  width: 350px;
  max-width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 25px;
  border: 1px solid #dddddd;
  border-radius: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #256d85;
    span {
      width: 32px;
      height: 32px;
      border-radius: 50px;
      border: 1px solid #dddddd;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  ul {
    margin-top: 15px;
    li {
      margin-top: 12px;
      font-size: 14px;
      color: #545454;
      b {
        color: #262626;
        font-weight: normal;
        font-family: "yekan-bold";
      }
    }
  }
  @media screen and (max-width: 992px) {
    width: 100%;
  }
`;
export const DashboardSignUpForm = styled.form`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr;
  gap: 15px;
  @media screen and (max-width: 992px) {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 30px;
    gap: 25px;
    margin-bottom: 25px;
  }
`;
export const InputContainer = styled.div`
  position: relative;
  display: block;
  @media screen and (max-width: 992px) {
    margin: 0;
  }
`;
export const InputLabel = styled.label`
  display: block;
  position: absolute;
  right: 10px;
  top: -6px;
  background-color: #fff;
  padding: 0 12px;
  font-size: 14px;
  color: #256d85;
`;
export const InputMain = styled.input`
  display: block;
  width: 100%;
  padding: 14px 15px;
  border-radius: 9px;
  border: 1px solid #ebebeb;
`;
export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-top: 50px;
  @media screen and (max-width: 992px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
