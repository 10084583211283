import styled from "styled-components";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

export const ItemLoading = styled(AiOutlineLoading3Quarters)`
  display: block;
  margin: 0 auto;
  font-size: 3rem;
  color: #256d85;
  margin-top: 6rem;
  -webkit-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
`;
