import React, { useRef, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { BsCheckLg } from "react-icons/bs";
import styled from "styled-components";
import Cookies from "universal-cookie";
import axios from "axios";
import { LoadingIcon } from "../LoadingPage/LoadingPageElements";
import { AiFillPrinter } from "react-icons/ai";
import { Link } from "react-router-dom";

export const cookies = new Cookies();

const ButtonEdit = styled.button`
  width: 30px;
  height: 30px;
  background-color: #256d85;
  font-size: 1.05rem;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  svg {
    position: relative;
    top: 4px;
    left: 1px;
  }
  &:hover {
    background-color: #184a5b;
  }
`;
const ButtonPrint = styled(Link)`
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #256d85;
  font-size: 1.05rem;
  color: #fff;
  margin-right: 5px;
  border-radius: 5px;
  cursor: pointer;
  svg {
    position: relative;
    top: 4px;
  }
  &:hover {
    background-color: #184a5b;
  }
`;
const ButtonSubmit = styled.button`
  width: 30px;
  height: 30px;
  background-color: #85b16f;
  font-size: 0.8rem;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  svg {
    position: relative;
    top: 4px;
  }
  &:hover {
    background-color: #669a4d;
  }
`;
const Input = styled.input`
  width: 100%;
  height: 30px;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  padding: 4px;
`;

const EditableTr = ({
  userId,
  name,
  shomareDadName,
  shomareParvande,
  phoneNumber,
  appointmentTime,
  createdTime,
  handelReload,
}) => {
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const shomareParvandeInput = useRef(null);
  const shomareDadnameInput = useRef(null);

  const appointmentDay = useRef(null);
  const appointmentMounth = useRef(null);
  const appointmentYear = useRef(null);

  const dateDay = useRef(null);
  const dateMounth = useRef(null);
  const dateYear = useRef(null);

  const handelEditUser = () => {
    setEdit(true);
  };

  const handelSendUpdatedUser = (e) => {
    setLoading(true);
    e.preventDefault();
    const date = `${dateYear.current.value}-${dateMounth.current.value}-${dateDay.current.value}`;
    const appointmenDate = `${appointmentYear.current.value}-${appointmentMounth.current.value}-${appointmentDay.current.value}`;

    const form = new FormData();
    form.append("shomare_parvande", shomareParvandeInput.current.value);
    form.append("shomare_dadname", shomareDadnameInput.current.value);
    form.append("tarikh", date);
    form.append("appointment_time", appointmenDate);

    const options = {
      method: "POST",
      url: `https://nobat-api.zagreus.company/api/office/appointments/${userId}/update`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${cookies.get("token")}`,
      },
      data: form,
    };

    axios
      .request(options)
      .then((response) => {
        if (response.status === 200) {
          handelReload();
          setLoading(false);
          setEdit(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (edit) {
    return (
      <tr>
        <td>{name}</td>
        <td>{phoneNumber}</td>
        <td>
          <Input
            type="text"
            defaultValue={shomareDadName}
            ref={shomareDadnameInput}
            disabled={loading}
          />
        </td>
        <td>
          <Input
            type="text"
            defaultValue={shomareParvande}
            disabled={loading}
            ref={shomareParvandeInput}
          />
        </td>
        <td>
          <Input
            type="text"
            style={{ width: 30 }}
            disabled={loading}
            defaultValue={createdTime.slice(8, 10)}
            ref={dateDay}
          />
          <Input
            type="text"
            disabled={loading}
            style={{ width: 30, margin: "0 4px" }}
            defaultValue={createdTime.slice(5, 7)}
            ref={dateMounth}
          />
          <Input
            type="text"
            style={{ width: 40 }}
            disabled={loading}
            defaultValue={createdTime.slice(0, 4)}
            ref={dateYear}
          />
        </td>
        <td>
          <Input
            type="text"
            style={{ width: 30 }}
            disabled={loading}
            defaultValue={appointmentTime.slice(8, 10)}
            ref={appointmentDay}
          />
          <Input
            type="text"
            style={{ width: 30, margin: "0 4px" }}
            disabled={loading}
            defaultValue={appointmentTime.slice(5, 7)}
            ref={appointmentMounth}
          />
          <Input
            type="text"
            style={{ width: 40 }}
            disabled={loading}
            defaultValue={appointmentTime.slice(0, 4)}
            ref={appointmentYear}
          />
        </td>
        <td>
          <ButtonSubmit type="button" onClick={handelSendUpdatedUser}>
            {loading ? (
              <LoadingIcon style={{ fontSize: " 0.8rem", color: "#fff" }} />
            ) : (
              <BsCheckLg />
            )}
          </ButtonSubmit>
          <ButtonPrint to={`/print/${userId}`} target="_blank">
            <AiFillPrinter />
          </ButtonPrint>
        </td>
      </tr>
    );
  } else {
    return (
      <tr>
        <td>{name}</td>
        <td>{phoneNumber}</td>
        <td>{shomareDadName}</td>
        <td>{shomareParvande}</td>
        <td>{createdTime}</td>
        <td>{appointmentTime}</td>
        <td>
          <ButtonEdit type="button" onClick={handelEditUser}>
            <FaEdit />
          </ButtonEdit>
          <ButtonPrint to={`/print/${userId}`} target="_blank">
            <AiFillPrinter />
          </ButtonPrint>
        </td>
      </tr>
    );
  }
};

export default EditableTr;
