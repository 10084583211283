import React, { useRef, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { BsCheckLg } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import styled from "styled-components";
import Cookies from "universal-cookie";
import axios from "axios";
import { LoadingIcon } from "../LoadingPage/LoadingPageElements";
import Swal from "sweetalert2";

export const cookies = new Cookies();

const ButtonEdit = styled.button`
  width: 30px;
  height: 30px;
  background-color: #256d85;
  font-size: 1.05rem;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  svg {
    position: relative;
    top: 4px;
    left: 1px;
  }
  &:hover {
    background-color: #184a5b;
  }
`;
const ButtonDelete = styled.button`
  width: 30px;
  height: 30px;
  background-color: #e33d3d;
  font-size: 1.05rem;
  color: #fff;
  border-radius: 5px;
  margin-right: 7px;
  cursor: pointer;
  svg {
    position: relative;
    top: 4px;
  }
  &:hover {
    background-color: #ae3333;
  }
`;
const ButtonSubmit = styled.button`
  width: 30px;
  height: 30px;
  background-color: #85b16f;
  font-size: 0.8rem;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  svg {
    position: relative;
    top: 4px;
  }
  &:hover {
    background-color: #669a4d;
  }
`;
const Input = styled.input`
  width: 100%;
  height: 30px;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  padding: 4px;
`;
const Select = styled.select`
  width: 100%;
  height: 30px;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  padding: 4px;
`;

const UserTr = ({
  handelReload,
  userId,
  name,
  phoneNumber,
  province,
  city,
  nationalCode,
  roleId,
}) => {
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const fullNameInput = useRef(null);
  const phoneNumberInput = useRef(null);
  const nationalCodeInput = useRef(null);
  const provinceInput = useRef(null);
  const cityInput = useRef(null);
  const roleIdSelect = useRef(null);

  const handelEditUser = () => {
    setEdit(true);
  };

  const handelSendUpdatedUser = (e) => {
    setLoading(true);
    e.preventDefault();
    const form = new FormData();
    form.append("full_name", fullNameInput.current.value);
    form.append("number", phoneNumberInput.current.value);
    form.append("national_code", nationalCodeInput.current.value);
    form.append("province", provinceInput.current.value);
    form.append("city", cityInput.current.value);
    form.append("role_id", roleIdSelect.current.value);

    const options = {
      method: "POST",
      url: `https://nobat-api.zagreus.company/api/admin/users/${userId}/update`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${cookies.get("token")}`,
      },
      data: form,
    };

    axios
      .request(options)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setEdit(false);
          handelReload();
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
          confirmButtonText: "بستن",
        });
      });
  };
  const handelDeleteUser = (e) => {
    setLoading(true);
    e.preventDefault();
    const options = {
      method: "POST",
      url: `https://nobat-api.zagreus.company/api/admin/users/${userId}/delete`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${cookies.get("token")}`,
      },
    };

    axios
      .request(options)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          handelReload();
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
          confirmButtonText: "بستن",
        });
      });
  };
  if (edit) {
    return (
      <tr>
        <td>
          <Select ref={roleIdSelect} defaultValue={roleId} disabled={loading}>
            <option value="0">کاربر</option>
            <option value="1">مدیرکل</option>
            <option value="2">دفتر</option>
          </Select>
        </td>
        <td>
          <Input
            type="text"
            defaultValue={name}
            ref={fullNameInput}
            disabled={loading}
          />
        </td>
        <td>
          <Input
            type="text"
            defaultValue={phoneNumber}
            ref={phoneNumberInput}
            disabled={loading}
          />
        </td>
        <td>
          <Input
            type="text"
            defaultValue={province}
            ref={provinceInput}
            disabled={loading}
          />
        </td>
        <td>
          <Input
            type="text"
            defaultValue={city}
            ref={cityInput}
            disabled={loading}
          />
        </td>
        <td>
          <Input
            type="text"
            defaultValue={nationalCode}
            ref={nationalCodeInput}
            disabled={loading}
          />
        </td>
        <td>
          <ButtonSubmit type="button" onClick={handelSendUpdatedUser}>
            {loading ? (
              <LoadingIcon style={{ fontSize: " 0.8rem", color: "#fff" }} />
            ) : (
              <BsCheckLg />
            )}
          </ButtonSubmit>
        </td>
      </tr>
    );
  } else {
    return (
      <tr>
        <td>
          {Number(roleId) === 0 ? "کاربر" : null}
          {Number(roleId) === 1 ? "مدیرکل" : null}
          {Number(roleId) === 2 ? "دفتر" : null}
        </td>
        <td>{name}</td>
        <td>{phoneNumber}</td>
        <td>{province}</td>
        <td>{city}</td>
        <td>{nationalCode}</td>
        <td>
          <ButtonEdit type="button" onClick={handelEditUser}>
            <FaEdit />
          </ButtonEdit>
          <ButtonDelete type="button" onClick={handelDeleteUser}>
            {loading ? (
              <LoadingIcon style={{ fontSize: " 0.8rem", color: "#fff" }} />
            ) : (
              <AiFillDelete />
            )}
          </ButtonDelete>
        </td>
      </tr>
    );
  }
};

export default UserTr;
