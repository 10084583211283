import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PrintPageContainer } from "./PrintPageElements";
import Cookies from "universal-cookie";
import axios from "axios";

export const cookies = new Cookies();

const PrintPage = () => {
  const [state, setState] = useState(null);
  let { id } = useParams();

  useEffect(() => {
    if (state === null) {
      handelGetInformation();
    }
  });
  const handelGetInformation = () => {
    const options = {
      method: "GET",
      url: `https://nobat-api.zagreus.company/api/office/appointments/${id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${cookies.get("token")}`,
      },
    };

    axios
      .request(options)
      .then((response) => {
        if (response.status === 200) {
          setState({ data: { ...response.data } });
          window.print();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <PrintPageContainer>
      <h1 className="title">به نام خدا</h1>
      <br />
      <br />
      <br />
      <div className="box">
        <h3>اطلاعات نوبت</h3>
        <br />
        <p>
          شماره دادنامه :
          <span>{state !== null ? state.data.shomare_dadname : null}</span>
        </p>
        <p>
          شماره پرونده :
          <span>{state !== null ? state.data.shomare_parvande : null}</span>
        </p>
        <p>
          تاریخ ایجاد پرونده :
          <span>{state !== null ? state.data.tarikh_jalali : null}</span>
        </p>
        <p>
          تاریخ نوبت :
          <span>
            {state !== null ? state.data.appointment_time_array.jalali : null}
          </span>
        </p>
      </div>

      <div className="box">
        <h3>اطلاعات دفتر</h3>
        <br />
        <p>
          نام دفتر :{" "}
          <span>{state !== null ? state.data.office.name : null}</span>
        </p>
        <p>
          آدرس: <span>{state !== null ? state.data.office.address : null}</span>
        </p>
        <p>
          شماره تماس:{" "}
          <span>{state !== null ? state.data.office.number : null}</span>
        </p>
      </div>

      <div className="box">
        <h3>اطلاعات متقاضی</h3>
        <br />
        <p>
          نام و نام خانوادگی :
          <span>{state !== null ? state.data.user.full_name : null}</span>
        </p>
        <p>
          شماره تماس :{" "}
          <span>{state !== null ? state.data.user.number : null}</span>
        </p>
        <p>
          استان: <span>{state !== null ? state.data.user.province : null}</span>
        </p>
        <p>
          شهر: <span>{state !== null ? state.data.user.city : null}</span>
        </p>
      </div>
    </PrintPageContainer>
  );
};

export default PrintPage;
