import styled from "styled-components";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
export const LoadingPageContainer = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
`;
export const LoadingIcon = styled(AiOutlineLoading3Quarters)`
  font-size: 3rem;
  color: #256d85;
  -webkit-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
`;
