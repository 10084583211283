import React from "react";
import { NavbrContainer, NavbarBrand } from "./NavbarElements";

const Navbar = () => {
  return (
    <NavbrContainer>
      <NavbarBrand to='/'>سامانه نوبت دهی</NavbarBrand>
    </NavbrContainer>
  );
};

export default Navbar;
