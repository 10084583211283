import React, { useRef, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { BsCheckLg } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import styled from "styled-components";
import Cookies from "universal-cookie";
import axios from "axios";
import { LoadingIcon } from "../LoadingPage/LoadingPageElements";
import Swal from "sweetalert2";

export const cookies = new Cookies();

const ButtonEdit = styled.button`
  width: 30px;
  height: 30px;
  background-color: #256d85;
  font-size: 1.05rem;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  svg {
    position: relative;
    top: 4px;
    left: 1px;
  }
  &:hover {
    background-color: #184a5b;
  }
`;
const ButtonDelete = styled.button`
  width: 30px;
  height: 30px;
  background-color: #e33d3d;
  font-size: 1.05rem;
  color: #fff;
  border-radius: 5px;
  margin-right: 7px;
  cursor: pointer;
  svg {
    position: relative;
    top: 4px;
  }
  &:hover {
    background-color: #ae3333;
  }
`;
const ButtonSubmit = styled.button`
  width: 30px;
  height: 30px;
  background-color: #85b16f;
  font-size: 0.8rem;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  svg {
    position: relative;
    top: 4px;
  }
  &:hover {
    background-color: #669a4d;
  }
`;
const Input = styled.input`
  width: 100%;
  height: 30px;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  padding: 4px;
`;
const Select = styled.select`
  width: 100%;
  height: 30px;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  padding: 4px;
`;

const OfficeTr = ({
  handelReload,
  officeId,
  name,
  address,
  userData,
  number,
  userId,
}) => {
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const nameInput = useRef(null);
  const addressrInput = useRef(null);
  const numberInput = useRef(null);
  const userIdInput = useRef(null);

  const handelEditUser = () => {
    setEdit(true);
  };
  const handelSendUpdatedUser = (e) => {
    setLoading(true);
    e.preventDefault();
    const form = new FormData();
    form.append("name", nameInput.current.value);
    form.append("address", addressrInput.current.value);
    form.append("number", numberInput.current.value);
    form.append("user_id", userIdInput.current.value);

    const options = {
      method: "POST",
      url: `https://nobat-api.zagreus.company/api/admin/office/${officeId}/update`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${cookies.get("token")}`,
      },
      data: form,
    };

    axios
      .request(options)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setEdit(false);
          handelReload();
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
          confirmButtonText: "بستن",
        });
      });
  };
  const handelDeleteUser = (e) => {
    setLoading(true);
    e.preventDefault();
    const options = {
      method: "POST",
      url: `https://nobat-api.zagreus.company/api/admin/office/${officeId}/delete`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${cookies.get("token")}`,
      },
    };
    axios
      .request(options)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          handelReload();
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
          confirmButtonText: "بستن",
        });
      });
  };
  if (edit) {
    return (
      <tr>
        <td>
          <Select ref={userIdInput} defaultValue={userId} disabled={loading}>
            {userData.map((e, i) => (
              <option key={i} value={e.id}>
                {e.full_name}
              </option>
            ))}
          </Select>
        </td>
        <td>
          <Input
            type="text"
            defaultValue={name}
            ref={nameInput}
            disabled={loading}
          />
        </td>
        <td>
          <Input
            type="text"
            defaultValue={number}
            ref={numberInput}
            disabled={loading}
          />
        </td>
        <td>
          <Input
            type="text"
            defaultValue={address}
            ref={addressrInput}
            disabled={loading}
          />
        </td>

        <td>
          <ButtonSubmit type="button" onClick={handelSendUpdatedUser}>
            {loading ? (
              <LoadingIcon style={{ fontSize: " 0.8rem", color: "#fff" }} />
            ) : (
              <BsCheckLg />
            )}
          </ButtonSubmit>
        </td>
      </tr>
    );
  } else {
    return (
      <tr>
        <td>
          {userData !== null
            ? userData.map((e) =>
                Number(e.id) === Number(userId) ? e.full_name : null
              )
            : null}
        </td>
        <td>{name}</td>
        <td>{number}</td>
        <td>{address}</td>
        <td>
          <ButtonEdit type="button" onClick={handelEditUser}>
            <FaEdit />
          </ButtonEdit>
          <ButtonDelete type="button" onClick={handelDeleteUser}>
            {loading ? (
              <LoadingIcon style={{ fontSize: " 0.8rem", color: "#fff" }} />
            ) : (
              <AiFillDelete />
            )}
          </ButtonDelete>
        </td>
      </tr>
    );
  }
};

export default OfficeTr;
