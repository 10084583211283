import styled from "styled-components";

export const Button = styled.div`
  display: block;
  width: 100%;
  height: 46px;
  background-color: #256d85;
  color: #fff;
  padding: 15px;
  text-align: center;
  border-radius: 9px;
  cursor: pointer;
  &:hover {
    background-color: #1c5365;
    color: #fff;
  }
`;
