import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import NotFoundPage from "./pages/NotFoundPage";
import DashbordHome from "./pages/Dashboard/DashbordHome";
import Dashbord from "./pages/Dashboard";
import Cookies from "universal-cookie";
import axios from "axios";
import ApiErrorPage from "./components/ApiError";
import DashboardAppointment from "./pages/Dashboard/DashboardAppointment";
import DashboardOfficeAppointment from "./pages/Dashboard/DashboardOfficeAppointment";
import DashboardAppointmentRequests from "./pages/Dashboard/DashboardAppointmentRequests";
import PrintPage from "./components/PrintPage";
import DashbordProfile from "./pages/Dashboard/DashbordProfile";
import DashboardManageUsers from "./pages/Dashboard/DashboardManageUsers";
import DashboardManageOffice from "./pages/Dashboard/DashboardManageOffice";
import DashboardAllAppointment from "./pages/Dashboard/DashboardAllAppointment";

export const cookies = new Cookies();

const App = () => {
  const [profileState, SetProfiletState] = useState(null);
  const [tokenValidation, setTokenValidation] = useState(false);
  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [networkError, setNetworkError] = useState(false);

  useEffect(() => {
    if (profileState === null) {
      const options = {
        method: "GET",
        url: `https://nobat-api.zagreus.company/api/profile`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${cookies.get("token")}`,
        },
      };
      axios
        .request(options)
        .then((response) => {
          if (response.status === 200) {
            SetProfiletState({ ...response.data });
            setTokenValidation(true);
            setLoading(false);
            setApiError(false);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.message === "Request failed with status code 401") {
            cookies.remove("token");
            setTokenValidation(false);
            setLoading(false);
            setApiError(false);
          } else if (error.message === "Network Error") {
            setNetworkError(true);
            setLoading(false);
            console.log(error);
          } else {
            setLoading(false);
            setApiError(true);
            console.log(error);
          }
        });
    }
  });
  const handelLogOut = () => {
    cookies.remove("token", { path: "/" });
    setTokenValidation(false);
    SetProfiletState(null);
  };
  const handelTokenValidation = () => {
    setTokenValidation(true);
  };
  if (networkError === false) {
    return (
      <Router basename="/">
        <Routes>
          <Route
            path="/"
            element={
              <Home
                tokenValidation={tokenValidation}
                handelTokenValidation={handelTokenValidation}
                loadingPage={loading}
                apiError={apiError}
                SetProfiletState={SetProfiletState}
              />
            }
          />
          <Route
            path="*"
            element={<NotFoundPage loadingPage={loading} apiError={apiError} />}
          />
          <Route
            path="dashboard"
            element={
              <Dashbord
                handelLogOut={handelLogOut}
                tokenValidation={tokenValidation}
                loadingPage={loading}
                apiError={apiError}
                profileState={profileState}
              />
            }
          >
            {profileState !== null ? (
              <>
                {profileState.role_title === "client" ? (
                  <>
                    <Route index element={<DashbordHome />} />
                    <Route
                      path="appointment"
                      element={<DashboardAppointment />}
                    />
                  </>
                ) : (
                  <Route index element={<DashboardAppointmentRequests />} />
                )}
                {profileState.role_title !== "sudo" && (
                  <Route
                    path="office"
                    element={<DashboardOfficeAppointment />}
                  />
                )}
                {profileState.role_title !== "client" && (
                  <Route path="profile" element={<DashbordProfile />} />
                )}
                {profileState.role_title === "sudo" && (
                  <Route path="users" element={<DashboardManageUsers />} />
                )}
                   {profileState.role_title === "sudo" && (
                  <Route path="allappointment" element={<DashboardAllAppointment />} />
                )}
                {profileState.role_title === "sudo" && (
                  <Route
                    path="officeadmin"
                    element={<DashboardManageOffice />}
                  />
                )}
              </>
            ) : null}
          </Route>
          <Route path="print/:id" element={<PrintPage />} />
        </Routes>
      </Router>
    );
  } else {
    return (
      <ApiErrorPage
        errorText={"لطفا از اتصال دستگاه خود به اینترنت مطمئن شوید."}
      />
    );
  }
};

export default App;
