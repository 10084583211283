import styled from "styled-components";
import { FaTimes } from "react-icons/fa";

export const ModalContainer = styled.aside`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  width: 100%;
  height: 100vh;
  background-color: #000000a2;
  animation: fadeIn 0.4s;
  z-index: 10001;
  div {
    width: 100%;
  }
`;

export const ModalBox = styled.div`
  position: relative;
  width: 700px !important;
  max-width: 90% !important;
  max-height: 85vh;
  background-color: #fff;
  padding: 40px 20px 20px;
  margin: 0 auto;
  border-radius: 15px;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const ModalTitle = styled.h4`
  color: #000;
  font-size: 1.3rem;
  margin-bottom: 15px;
`;

export const ModalClose = styled(FaTimes)`
  position: absolute;
  left: 15px;
  top: 15px;
  font-size: 1.2rem;
  cursor: pointer;
`;
