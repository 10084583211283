import React, { useEffect, useRef, useState } from "react";
import {
  DashbordHomeTitle,
  InputContainer,
  InputLabel,
  InputMain,
} from "../DashbordHome/DashbordHomeEelements";
import {
  CreateAccountContainer,
  DashboardAppointmentContainer,
  AddUserButton,
} from "./DashboardManageUsersElements";
import axios from "axios";
import { ItemLoading } from "../../../components/ItemLoading";
import Cookies from "universal-cookie";
import {
  AuthAlert,
  AuthSelect,
} from "../../../components/AuthForm/AuthFormElements";
import { Button } from "../../../components/Button";
import { LoadingIcon } from "../../Home/HomeElements";
import Swal from "sweetalert2";
import Modal from "../../../components/Modal";
import OfficeTr from "../../../components/OfficeTr";

export const cookies = new Cookies();

const DashboardManageOffice = () => {
  const [state, setState] = useState({
    data: [],
    requestNum: 0,
    loading: false,
    errorText: "",
  });
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const usernameInputSignUp = useRef(null);
  const phoneInputSignUp = useRef(null);
  const addressInputSignUp = useRef(null);
  const userInputSignUp = useRef(null);

  const [toggleModal, setToggleModal] = useState(false);

  useEffect(() => {
    if (loading === true) {
      handelAddOptions();
      handelAddUserData();
    }
  });
  const handelInfoModal = () => {
    setToggleModal(!toggleModal);
  };
  const handelAddUserData = () => {
    const options = {
      method: "GET",
      url: `https://nobat-api.zagreus.company/api/admin/users`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${cookies.get("token")}`,
      },
    };
    axios
      .request(options)
      .then((response) => {
        if (response.status === 200) {
          setUserData([...response.data.data]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handelReload = () => {
    handelAddOptions();
  };
  const handelCreateOffice = (e) => {
    e.preventDefault();
    setState({ ...state, loading: true });
    console.log(userInputSignUp.current.value);
    const form = new FormData();
    form.append("user_id", Number(userInputSignUp.current.value));
    form.append("name", usernameInputSignUp.current.value);
    form.append("number", phoneInputSignUp.current.value);
    form.append("address", addressInputSignUp.current.value);

    const options = {
      method: "POST",
      url: "https://nobat-api.zagreus.company/api/admin/office/create",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${cookies.get("token")}`,
      },
      data: form,
    };

    axios
      .request(options)
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            text: "کاربر جدید با موفقیت ثبت شد",
            confirmButtonText: "بستن",
          });
          handelInfoModal();
          setState({ ...state, loading: false });
          usernameInputSignUp.current.value = "";
          phoneInputSignUp.current.value = "";
          addressInputSignUp.current.value = "";
          userInputSignUp.current.value = 0;
          handelReload();
        }
      })
      .catch((error) => {
        setState({ ...state, loading: false });
        console.log(error);
        if (error.response !== undefined) {
          setState({ ...state, errorText: error.response.data.message });
        }
      });
  };
  const handelAddOptions = () => {
    const options = {
      method: "GET",
      url: `https://nobat-api.zagreus.company/api/office/list`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${cookies.get("token")}`,
      },
    };
    axios
      .request(options)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setState({ ...state, data: [...response.data.data] });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <DashboardAppointmentContainer>
      <DashbordHomeTitle>
        <h2>مدیریت دفاتر</h2>
        <span>
          امروز،
          {new Date().toLocaleDateString("fa-IR", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </span>
      </DashbordHomeTitle>
      {toggleModal === true ? (
        <Modal handelModalToggle={handelInfoModal} title="">
          {state.errorText !== "" ? (
            <AuthAlert>{state.errorText}</AuthAlert>
          ) : (
            ""
          )}
          <CreateAccountContainer>
            <InputContainer style={{ marginTop: 0 }}>
              <InputLabel>کاربر</InputLabel>
              <AuthSelect ref={userInputSignUp}>
                {userData !== null &&
                  userData.map((e, i) => (
                    <option key={i} value={e.id}>
                      {e.full_name}
                    </option>
                  ))}
              </AuthSelect>
            </InputContainer>
            <InputContainer style={{ marginTop: 0 }}>
              <InputLabel>نام دفتر</InputLabel>
              <InputMain type="text" ref={usernameInputSignUp} />
            </InputContainer>
            <InputContainer style={{ marginTop: 0 }}>
              <InputLabel>شماره تماس</InputLabel>
              <InputMain type="text" ref={phoneInputSignUp} />
            </InputContainer>
            <InputContainer style={{ marginTop: 0 }}>
              <InputLabel>آدرس</InputLabel>
              <InputMain type="text" ref={addressInputSignUp} />
            </InputContainer>
          </CreateAccountContainer>
          <Button type="submit" onClick={handelCreateOffice}>
            {state.loading ? <LoadingIcon /> : "ایجاد دفتر"}
          </Button>
        </Modal>
      ) : (
        ""
      )}
      <AddUserButton type="button" onClick={handelInfoModal}>
        افزودن دفتر
      </AddUserButton>
      {loading === true ? (
        <ItemLoading />
      ) : state.data.length === 0 ? (
        <p className="empty">درحال حاضر دفتری ثبت نشده است.</p>
      ) : (
        <>
          <div className="tabel-responsive">
            <table>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">نام دفتر</th>
                  <th scope="col">شماره تماس</th>
                  <th scope="col">آدرس</th>
                  <th scope="col">*</th>
                </tr>
              </thead>
              <tbody>
                {state.data.length !== 0
                  ? state.data.map((e, i) => (
                      <OfficeTr
                        key={i}
                        handelReload={handelReload}
                        officeId={e.id}
                        userId={e.user_id}
                        name={e.name}
                        number={e.number}
                        address={e.address}
                        userData={userData}
                      />
                    ))
                  : ""}
              </tbody>
            </table>
          </div>
        </>
      )}
    </DashboardAppointmentContainer>
  );
};

export default DashboardManageOffice;
