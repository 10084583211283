import React, { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import ApiErrorPage from "../../components/ApiError";
import LoadingPage from "../../components/LoadingPage";
import Sidebar from "../../components/Sidebar";
import {
  DahsboardContainer,
  DashboardMainContent,
  DashboardMenu,
  HamburgerMenuOutSide,
  HamburgerMenu,
} from "./DashboardElements";
import { GiHamburgerMenu } from "react-icons/gi";

const Dashbord = ({
  tokenValidation,
  loadingPage,
  apiError,
  profileState,
  handelLogOut,
}) => {
  const [show, setShow] = useState(false);
  const handelShowMenuMobile = () => {
    setShow(true);
  };
  const handelHideMenuMobile = () => {
    setShow(false);
  };
  if (loadingPage) {
    return <LoadingPage />;
  } else if (apiError) {
    return <ApiErrorPage />;
  } else {
    if (tokenValidation === false) {
      return <Navigate replace to="/" />;
    } else {
      return (
        <>
          <DahsboardContainer>
            <HamburgerMenu>
              <GiHamburgerMenu onClick={handelShowMenuMobile} />
            </HamburgerMenu>
            <HamburgerMenuOutSide show={show} onClick={handelHideMenuMobile} />
            <DashboardMenu>
              <Sidebar
                profileState={profileState}
                show={show}
                handelLogOut={handelLogOut}
                handelHideMenuMobile={handelHideMenuMobile}
              />
            </DashboardMenu>
            <DashboardMainContent>
              <Outlet />
            </DashboardMainContent>
          </DahsboardContainer>
        </>
      );
    }
  }
};

export default Dashbord;
