import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import {
  DashbordHomeContainer,
  DashbordHomeNear,
  DashbordHomeTitle,
  DashboardSignUpForm,
  InputContainer,
  InputLabel,
  InputMain,
} from "./DashbordHomeEelements";
import { BsFillCalendarWeekFill } from "react-icons/bs";
import { Button } from "../../../components/Button";
import { LoadingIcon } from "../../Home/HomeElements";
import { AuthAlert } from "../../../components/AuthForm/AuthFormElements";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { ItemLoading } from "../../../components/ItemLoading";

export const cookies = new Cookies();

const DashbordHome = () => {
  const [state, setState] = useState({
    data: [],
    errorText: "",
    loading: false,
  });
  const [loading, setLoading] = useState(true);
  const [near, setNear] = useState(null);

  useEffect(() => {
    if (loading === true) {
      handelAddOptions();
    }
  });

  const shomareParvande = useRef(null);
  const shomareDadname = useRef(null);
  const yearInput = useRef(null);
  const monthInput = useRef(null);
  const dayInput = useRef(null);

  const handelAddOptions = () => {
    const options = {
      method: "GET",
      url: `https://nobat-api.zagreus.company/api/appointment/list`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${cookies.get("token")}`,
      },
    };
    axios
      .request(options)
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          setLoading(false);
          setNear(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handelConfrim = (e) => {
    e.preventDefault();
    setState({ ...state, loading: true });
    const date = `${yearInput.current.value}-${monthInput.current.value}-${dayInput.current.value}`;
    const form = new FormData();
    form.append("shomare_parvande", shomareParvande.current.value);
    form.append("shomare_dadname", shomareDadname.current.value);
    form.append("tarikh", date);

    const options = {
      method: "POST",
      url: "https://nobat-api.zagreus.company/api/appointment/create",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${cookies.get("token")}`,
      },
      data: form,
    };

    axios
      .request(options)
      .then((response) => {
        if (response.status === 200) {
          setState({ ...state, errorText: ""});
          Swal.fire({
            icon: "success",
            text: "نوبت شما با موفقیت ثبت شد",
            confirmButtonText: "بستن",
          });
        }
      })
      .catch((error) => {
        setState({ ...state, loading: false });
        console.log(error);
        if (error.response !== undefined) {
          setState({ ...state, errorText: error.response.data.message });
        }
      });
  };
  return (
    <DashbordHomeContainer>
      <DashbordHomeTitle>
        <h2>پیشخوان کاربری</h2>
        <span>
          امروز،
          {new Date().toLocaleDateString("fa-IR", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </span>
      </DashbordHomeTitle>
      {loading === true ? (
        <ItemLoading />
      ) : (
        <>
          {near[0] !== undefined ? (
            <DashbordHomeNear>
              <div className="header">
                <div className="child">
                  <h3>نزدیک ترین نوبت</h3>
                </div>
                <div className="child">
                  <span>
                    <BsFillCalendarWeekFill />
                  </span>
                </div>
              </div>
              <ul>
                <li>
                  شماره دادنامه :{" "}
                  <b>{near !== null ? near[0].shomare_dadname : ""}</b>
                </li>
                <li>
                  شماره پرونده :{" "}
                  <b>{near !== null ? near[0].shomare_parvande : ""}</b>
                </li>
                <li>
                  نام دفتر : <b>{near !== null ? near[0].office.name : ""}</b>
                </li>
                <li>
                  آدرس : <b>{near !== null ? near[0].office.address : ""}</b>
                </li>
                <li>
                  شماره تماس :{" "}
                  <b>{near !== null ? near[0].office.number : ""}</b>
                </li>
                <li>
                  تاریخ :
                  <b style={{ direction: "ltr", display: "inline-block" }}>
                    {near !== null ? near[0].tarikh_jalali : ""}
                  </b>
                </li>
              </ul>
            </DashbordHomeNear>
          ) : (
            <div style={{ marginTop: 30 }}></div>
          )}

          <DashbordHomeTitle>
            <h2>ثبت نوبت</h2>
          </DashbordHomeTitle>
          {state.errorText !== "" ? (
            <AuthAlert>{state.errorText}</AuthAlert>
          ) : (
            ""
          )}
          <DashboardSignUpForm>
            <InputContainer>
              <InputLabel>شماره پرونده</InputLabel>
              <InputMain type="text" ref={shomareParvande} />
            </InputContainer>
            <InputContainer>
              <InputLabel>شماره دادنامه</InputLabel>
              <InputMain type="text" ref={shomareDadname} />
            </InputContainer>
            <InputContainer>
              <InputLabel>سال</InputLabel>
              <InputMain type="text" placeholder="1401" ref={yearInput} />
            </InputContainer>
            <InputContainer>
              <InputLabel>ماه</InputLabel>
              <InputMain type="text" placeholder="10" ref={monthInput} />
            </InputContainer>
            <InputContainer>
              <InputLabel>روز</InputLabel>
              <InputMain type="text" placeholder="08" ref={dayInput} />
            </InputContainer>
          </DashboardSignUpForm>
          <div style={{ textAlign: "center" }}>
            <Button
              style={{ width: 300, maxWidth: "100%", margin: "0 auto" }}
              type="submit"
              onClick={(e) => {
                handelConfrim(e);
              }}
            >
              {state.loading ? <LoadingIcon /> : "ثبت نام"}
            </Button>
          </div>
        </>
      )}
    </DashbordHomeContainer>
  );
};

export default DashbordHome;
