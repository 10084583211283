import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const SidebarContainer = styled.div`
  position: relative;
  background-color: #256d85;
  width: 300px;
  height: 95vh;
  border-radius: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1000;
  @media screen and (max-width: 1300px) {
    right: 10px;
    width: 240px;
  }
  @media screen and (max-width: 992px) {
    position: fixed;
    width: 300px;
    top: 0;
    bottom: 0;
    height: 100vh;
    border-radius: 0;
    opacity: ${({ show }) => (show ? "1" : "0")};
    visibility: ${({ show }) => (show ? "visible" : "hidden")};
    right: ${({ show }) => (show ? "0" : "-100%")};
  }
`;
export const SideHeader = styled.div`
  background-color: #1a4a5a;
  padding: 15px;
`;

export const SideImg = styled.div`
  display: block;
  width: 100px;
  height: 100px;
  background-color: #256d85;
  color: #fff;
  text-align: center;
  font-size: 2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-flow: column;
  margin: 0px auto 15px;
`;

export const SideName = styled.h5`
  display: block;
  color: #fff;
  text-align: center;
  font-size: 1.2rem;
`;
export const SidePhoneNumber = styled.span`
  display: block;
  color: #ffffffc3;
  text-align: center;
  font-size: 0.9rem;
  margin-top: 15px;
`;
export const SideNav = styled.ul`
  padding: 15px;
  display: block;
`;

export const SideItem = styled(NavLink)`
  list-style: none;
  margin: 10px 0;
  display: block;
  color: #fff;
  padding: 15px 13px;
  border-radius: 10px;
  svg {
    vertical-align: middle;
    margin-left: 5px;
  }
  &.active {
    background-color: #1a4a5a;
  }
`;

export const SideLogout = styled.button`
  width: 90%;
  color: #fff;
  background-color: #cc2a3b;
  padding: 13px 0 !important;
  border-radius: 10px;
  position: absolute;
  left: 0;
  margin: 0 auto;
  right: 0;
  bottom: 15px;
  cursor: pointer;
  @media screen and (max-width: 992px) {
    position: fixed;
    width: 250px;
    right: 25px;
    bottom: 10px;
    z-index: 1001;
    margin: 0;
  }
  &:hover {
    background-color: #a41b29;
  }
`;
