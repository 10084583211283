import { Link } from "react-router-dom";
import styled from "styled-components";

export const NavbrContainer = styled.nav`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  padding:24px;
  margin: 0 auto;
  background-color: #256D85;
  z-index: 1000;
`;
export const NavbarBrand = styled(Link)`
  display: flex;
  width: 100%;
  font-family: "yekan-bold";
  color: #fff;
  font-size: 1.3rem;
  justify-content: space-between;
`;
