import React, { useEffect, useState } from "react";
import { DashbordHomeTitle } from "../DashbordHome/DashbordHomeEelements";
import { DashboardAppointmentContainer } from "./DashboardAppointmentElements";
import axios from "axios";
import { ItemLoading } from "../../../components/ItemLoading";
import Cookies from "universal-cookie";
import EditableTr from "../../../components/EditableTr";

export const cookies = new Cookies();

const DashboardAppointmentRequests = () => {
  const [state, setState] = useState({
    data: [],
    requestNum: 0,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading === true) {
      handelAddOptions();
    }
  });
  const handelReload = () => {
    handelAddOptions();
  };
  const handelAddOptions = () => {
    const options = {
      method: "GET",
      url: `https://nobat-api.zagreus.company/api/office/appointments`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${cookies.get("token")}`,
      },
    };
    axios
      .request(options)
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          setLoading(false);
          setState({ ...state, data: [...response.data.data] });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <DashboardAppointmentContainer>
      <DashbordHomeTitle>
        <h2>لیست نوبت ها</h2>
        <span>
          امروز،
          {new Date().toLocaleDateString("fa-IR", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </span>
      </DashbordHomeTitle>
      {loading === true ? (
        <ItemLoading />
      ) : state.data.length === 0 ? (
        <p className="empty">درحال حاضر نوبتی برای شما ثبت نشده است.</p>
      ) : (
        <div className="tabel-responsive">
          <table>
            <thead>
              <tr>
                <th scope="col">نام کاربر</th>
                <th scope="col">شماره تماس </th>
                <th scope="col">شماره دادنامه</th>
                <th scope="col">شماره پرونده</th>
                <th scope="col">تاریخ پرونده</th>
                <th scope="col">تاریخ نوبت</th>
                <th scope="col">*</th>
              </tr>
            </thead>
            <tbody>
              {state.data.length !== 0
                ? state.data.map((e, i) => (
                    <EditableTr
                      key={i}
                      handelReload={handelReload}
                      userId={e.id}
                      name={e.user.full_name}
                      shomareDadName={e.shomare_dadname}
                      shomareParvande={e.shomare_parvande}
                      phoneNumber={e.user.number}
                      appointmentTime={e.appointment_time_array.jalali}
                      createdTime={e.tarikh_jalali}
                    />
                  ))
                : ""}
            </tbody>
          </table>
        </div>
      )}
    </DashboardAppointmentContainer>
  );
};

export default DashboardAppointmentRequests;
