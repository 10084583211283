import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "../Button";
import {
  AuthFormContainer,
  AuthFormTab,
  AuthFormTabs,
  AuthLogin,
  AuthSingUp,
  InputContainer,
  InputLabel,
  InputMain,
  Form,
  AuthAlert,
  AuthSelect,
} from "./AuthFormElements";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { LoadingIcon } from "../../pages/Home/HomeElements";

const cookies = new Cookies();

const AuthForm = ({ handelTokenValidation, SetProfiletState }) => {
  const [tab, setTab] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [province, setProvince] = useState([]);
  const [city, setCity] = useState([]);
  const [cityCurrent, setCityCurrent] = useState([]);
  const [state, setState] = useState({
    cpoptions: false,
    errorText: "",
    loading: false,
  });
  //login
  const usernameInput = useRef(null);
  const passwordInput = useRef(null);
  //SignUp
  const usernameInputSignUp = useRef(null);
  const phoneInputSignUp = useRef(null);
  const passwordInputSignUp = useRef(null);
  const passwordConfirmInputSignUp = useRef(null);
  const nationalCodeInputSignUp = useRef(null);
  const provinceInputSignUp = useRef(null);
  const cityInputSignUp = useRef(null);

  const navigate = useNavigate();
  useEffect(() => {
    handelAddOptions();
  });

  const handelAddOptions = () => {
    if (state.cpoptions === false) {
      setState({ ...state, cpoptions: true });
      const optionsProvince = {
        method: "GET",
        url: `https://nobat-api.zagreus.company/api/data/provinces`,
        headers: {
          Accept: "application/json",
          "Content-Type":
            "multipart/form-data; boundary=---011000010111000001101001",
        },
      };
      axios
        .request(optionsProvince)
        .then((response) => {
          if (response.status === 200) {
            setProvince([...response.data]);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      const optionsCity = {
        method: "GET",
        url: `https://nobat-api.zagreus.company/api/data/cities`,
        headers: {
          Accept: "application/json",
          "Content-Type":
            "multipart/form-data; boundary=---011000010111000001101001",
        },
      };
      axios
        .request(optionsCity)
        .then((response) => {
          if (response.status === 200) {
            setCity([...response.data]);
            const current = response.data.filter((e) => e.province_id === 1);
            setCityCurrent([...current]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handelTabsToggle = (e) => {
    setActiveTab(Number(e.target.getAttribute("data-num")));
    setState({ ...state, errorText: "" });
    if (Number(e.target.getAttribute("data-num")) === 0) {
      setTab(true);
    } else if (Number(e.target.getAttribute("data-num")) === 1) {
      setTab(false);
    }
  };
  const handelLogin = (e) => {
    e.preventDefault();
    setState({ ...state, loading: true });
    const form = new FormData();
    form.append("number", usernameInput.current.value);
    form.append("password", passwordInput.current.value);
    form.append("device", "Website");

    const options = {
      method: "POST",
      url: "https://nobat-api.zagreus.company/api/auth/login",
      headers: {
        Accept: "application/json",
        "Content-Type":
          "multipart/form-data; boundary=---011000010111000001101001",
      },
      data: form,
    };

    axios
      .request(options)
      .then((response) => {
        if (response.status === 200) {
          setState({ ...state, errorText: "" });
          cookies.set("token", response.data.token, {
            path: "/",
            secure: true,
            maxAge: 90000000,
          });
          handelDashboard();
        }
      })
      .catch((error) => {
        setState({ ...state, loading: false });
        console.log(error);
        if (error.response !== undefined) {
          setState({ ...state, errorText: error.response.data.message });
        }
      });
  };
  const handelSignUp = (e) => {
    e.preventDefault();
    setState({ ...state, loading: true });
    const provinceInputSignUpName = province.filter(
      (e) => e.id === Number(provinceInputSignUp.current.value)
    );
    console.log(passwordInputSignUp.current.value);
    const form = new FormData();
    form.append("full_name", usernameInputSignUp.current.value);
    form.append("number", phoneInputSignUp.current.value);
    form.append("password", passwordInputSignUp.current.value);
    form.append(
      "password_confirmation",
      passwordConfirmInputSignUp.current.value
    );
    form.append("national_code", nationalCodeInputSignUp.current.value);
    form.append("province", provinceInputSignUpName[0].name);
    form.append("city", cityInputSignUp.current.value);
    form.append("device", "Website");

    const options = {
      method: "POST",
      url: "https://nobat-api.zagreus.company/api/auth/register",
      headers: {
        Accept: "application/json",
        "Content-Type":
          "multipart/form-data; boundary=---011000010111000001101001",
      },
      data: form,
    };

    axios
      .request(options)
      .then((response) => {
        if (response.status === 200) {
          setState({ ...state, errorText: "" });
          cookies.set("token", response.data.token, {
            path: "/",
            secure: true,
            maxAge: 90000000,
          });
          handelDashboard();
        }
      })
      .catch((error) => {
        setState({ ...state, loading: false });
        console.log(error);
        if (error.response !== undefined) {
          setState({ ...state, errorText: error.response.data.message });
        }
      });
  };
  const handelDashboard = () => {
    const options = {
      method: "GET",
      url: `https://nobat-api.zagreus.company/api/profile`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${cookies.get("token")}`,
      },
    };
    axios
      .request(options)
      .then((response) => {
        if (response.status === 200) {
          setState({ ...state, loading: false });
          SetProfiletState({ ...response.data });
          navigate("/dashboard");
          handelTokenValidation();
        }
      })
      .catch((error) => {
        setState({ ...state, loading: false });
        console.log(error);
      });
  };

  const handelChangeCity = (e) => {
    const current = city.filter(
      (el) => el.province_id === Number(e.target.value)
    );
    setCityCurrent([...current]);
  };
  return (
    <AuthFormContainer>
      <AuthFormTabs>
        <AuthFormTab
          className={activeTab === 0 ? "active" : ""}
          data-num="0"
          onClick={(e) => {
            handelTabsToggle(e);
          }}
        >
          ورود
        </AuthFormTab>
        <AuthFormTab
          className={activeTab === 1 ? "active" : ""}
          data-num="1"
          onClick={(e) => {
            handelTabsToggle(e);
          }}
        >
          ثبت نام
        </AuthFormTab>
      </AuthFormTabs>
      <AuthLogin show={tab}>
        {state.errorText !== "" ? <AuthAlert>{state.errorText}</AuthAlert> : ""}
        <Form>
          <InputContainer>
            <InputLabel>شماره تلفن</InputLabel>
            <InputMain type="text" ref={usernameInput} />
          </InputContainer>
          <InputContainer>
            <InputLabel>رمزعبور</InputLabel>
            <InputMain type="password" ref={passwordInput} />
          </InputContainer>
          <Button type="submit" onClick={handelLogin}>
            {state.loading ? <LoadingIcon /> : "ورود"}
          </Button>
        </Form>
      </AuthLogin>
      <AuthSingUp show={!tab}>
        {state.errorText !== "" ? <AuthAlert>{state.errorText}</AuthAlert> : ""}
        <Form>
          <InputContainer>
            <InputLabel>نام و نام خانوادگی</InputLabel>
            <InputMain type="text" ref={usernameInputSignUp} />
          </InputContainer>
          <InputContainer>
            <InputLabel>شماره تماس</InputLabel>
            <InputMain type="text" ref={phoneInputSignUp} />
          </InputContainer>
          <InputContainer>
            <InputLabel>رمزعبور</InputLabel>
            <InputMain type="password" ref={passwordInputSignUp} />
          </InputContainer>
          <InputContainer>
            <InputLabel>تکرار رمز عبور</InputLabel>
            <InputMain type="password" ref={passwordConfirmInputSignUp} />
          </InputContainer>
          <InputContainer>
            <InputLabel>کد ملی</InputLabel>
            <InputMain type="text" ref={nationalCodeInputSignUp} />
          </InputContainer>
          <InputContainer>
            <InputLabel>استان</InputLabel>
            <AuthSelect
              ref={provinceInputSignUp}
              onChange={(e) => handelChangeCity(e)}
            >
              {province.map((e, i) => {
                if (i === 0) {
                  return (
                    <option value={e.id} key={i} defaultValue>
                      {e.name}
                    </option>
                  );
                } else {
                  return (
                    <option value={e.id} key={i}>
                      {e.name}
                    </option>
                  );
                }
              })}
            </AuthSelect>
          </InputContainer>
          <InputContainer>
            <InputLabel>شهر</InputLabel>
            <AuthSelect ref={cityInputSignUp}>
              {cityCurrent.map((e, i) => {
                if (i === 0) {
                  return (
                    <option value={e.name} key={i} defaultValue>
                      {e.name}
                    </option>
                  );
                } else {
                  return (
                    <option value={e.name} key={i}>
                      {e.name}
                    </option>
                  );
                }
              })}
            </AuthSelect>
          </InputContainer>
          <Button type="submit" onClick={handelSignUp}>
            {state.loading ? <LoadingIcon /> : "ثبت نام"}
          </Button>
        </Form>
      </AuthSingUp>
    </AuthFormContainer>
  );
};

export default AuthForm;
